body {
  box-sizing: border-box;
  background-color: black;
}
#root {background-color:#fff;width:100%}
.section {
  float:left;
  padding:0;
  margin:0;
}

.clmn {
  float:left;
}
.clmn p {
  display: block;
  margin-top:8px 0px;
}

.clmn.box {
  width:100vw;

}
.total {
  width:100%;
  font-weight: bold;;
}
/** ITEM ***/
.item {
  border-bottom:1px solid #ddd;
  padding:10px 0px 10px 0px;
  padding:8px 15px;
  margin:10px 0px;

}
.item.first-item {
  color:#000;
  background: #8FBC8F;
  border-radius: 5px;;
  color:#fff;
}
.msg_name {
  font-weight: bold;
  font-size:1.2em;
  text-transform: capitalize;
}
.msg_date {
  font-size:.8em;
  text-align: right;
  font-weight: bold;
 
}
ul.menu li{
  display:block;
  float:left;
  border-bottom: 1px solid #ddd;
  width:100%;
  padding:10px 20px;
}
@media print { 
  #root{width:100%}
}
@media only screen and (max-width: 1024px) {
  .clmn.left {display: none;}
  .clmn.box {
    width:100vw;
  
  }
}